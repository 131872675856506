/**
 * @generated SignedSource<<2823101e5c4916f65a6ea4cfce003249>>
 * @relayHash 9fad22a281a083d7cbfc16f572f4c3b5
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9fad22a281a083d7cbfc16f572f4c3b5

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TournamentPublicLayoutQuery$variables = {
  hasTournamentEntityId: boolean;
  tournamentEntityId: number;
};
export type TournamentPublicLayoutQuery$data = {
  readonly controllers?: ReadonlyArray<{
    readonly entityId: number;
  } | null | undefined>;
  readonly tournament?: {
    readonly categories: ReadonlyArray<{
      readonly entityId: number;
      readonly name: string;
    }>;
    readonly date: string;
    readonly name: string;
    readonly registration: {
      readonly hasTitles: boolean;
    } | null | undefined;
    readonly videos: ReadonlyArray<{
      readonly entityId: number;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"TournamentInformation_tournament" | "TournamentVideos_tournament">;
  } | null | undefined;
};
export type TournamentPublicLayoutQuery = {
  response: TournamentPublicLayoutQuery$data;
  variables: TournamentPublicLayoutQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasTournamentEntityId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tournamentEntityId"
},
v2 = [
  {
    "kind": "Literal",
    "name": "isVisible",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "tournamentEntityId",
    "variableName": "tournamentEntityId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "entityId",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = [
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "tournamentEntityId"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "hideEmpty",
    "value": true
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasTitles",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "info",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TournamentPublicLayoutQuery",
    "selections": [
      {
        "condition": "hasTournamentEntityId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Controller",
            "kind": "LinkedField",
            "name": "controllers",
            "plural": true,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Tournament",
            "kind": "LinkedField",
            "name": "tournament",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TournamentInformation_tournament"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TournamentVideos_tournament"
              },
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": "categories(hideEmpty:true)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationSetup",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentVideo",
                "kind": "LinkedField",
                "name": "videos",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TournamentPublicLayoutQuery",
    "selections": [
      {
        "condition": "hasTournamentEntityId",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "Controller",
            "kind": "LinkedField",
            "name": "controllers",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Tournament",
            "kind": "LinkedField",
            "name": "tournament",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentRegistrationSetup",
                "kind": "LinkedField",
                "name": "registration",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isOpen",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registrationType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registrationStartDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "registrationEndDate",
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  (v9/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cover",
                "storageKey": null
              },
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Address",
                "kind": "LinkedField",
                "name": "address",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fullAddress",
                    "storageKey": null
                  },
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "phoneNumber",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "website",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentBanner",
                "kind": "LinkedField",
                "name": "tournamentBanners",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tournamentEntityId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "banner",
                    "storageKey": null
                  },
                  (v12/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TournamentVideo",
                "kind": "LinkedField",
                "name": "videos",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "Category",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": "categories(hideEmpty:true)"
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "id": "9fad22a281a083d7cbfc16f572f4c3b5",
    "metadata": {},
    "name": "TournamentPublicLayoutQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "de4618afe70783c66ce40e575b8c7aa1";

export default node;
