import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';

import Link from 'next/link';
import { FC, ReactNode, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useRouteIsChanging } from '@ktech/components';
import { TournamentPublicLayoutQuery as TournamentPublicLayoutQueryType } from './__generated__/TournamentPublicLayoutQuery.graphql';
export type { TournamentPublicLayoutQueryType };

export const TournamentPublicLayoutQuery = graphql`
    query TournamentPublicLayoutQuery($tournamentEntityId: Int!, $hasTournamentEntityId: Boolean!) {
        controllers(tournamentEntityId: $tournamentEntityId, isVisible: true)
            @include(if: $hasTournamentEntityId) {
            entityId
        }
        tournament(entityId: $tournamentEntityId) @include(if: $hasTournamentEntityId) {
            ...TournamentInformation_tournament
            ...TournamentVideos_tournament
            name
            date
            categories(hideEmpty: true) {
                entityId
                name
            }
            registration {
                hasTitles
            }
            videos {
                entityId
            }
        }
    }
`;

type Props = {
    tournamentEntityId: number;
    categoryEntityId?: number | null;
    pageKey:
        | 'information'
        | 'live-updates'
        | 'participants'
        | 'officials'
        | 'draws'
        | 'results'
        | 'videos'
        | 'officials';
    children:
        | ReactNode
        | ((tournament: TournamentPublicLayoutQueryType['response']['tournament']) => ReactNode);
};

const TournamentPublicLayout: FC<Props> = ({
    tournamentEntityId,
    categoryEntityId,
    children,
    pageKey,
}) => {
    const theme = useTheme();
    const isRouteChanging = useRouteIsChanging();
    const routePath = `/tournament/${tournamentEntityId}`;

    const query = useLazyLoadQuery<TournamentPublicLayoutQueryType>(
        TournamentPublicLayoutQuery,
        {
            tournamentEntityId,
            hasTournamentEntityId: !!tournamentEntityId,
        },
        { fetchPolicy: 'store-or-network' }
    );

    const [drawsAnchorEl, setDrawsAnchorEl] = useState<null | HTMLElement>(null);
    const [resultsAnchorEl, setResultsAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        setDrawsAnchorEl(null);
        setResultsAnchorEl(null);
    }, [isRouteChanging]);

    return (
        <>
            <Box
                sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.getContrastText(theme.palette.primary.main),
                    py: 2,
                    mb: 3,
                }}
            >
                <Container>
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h5" component="h1" fontWeight={500}>
                            {query.tournament?.name}
                        </Typography>
                        <Typography variant="subtitle1" whiteSpace="nowrap">
                            <FormattedDate
                                value={query.tournament?.date}
                                year="numeric"
                                month="long"
                                day="numeric"
                            />
                        </Typography>
                    </Stack>
                </Container>
            </Box>

            <Container>
                <ButtonGroup
                    variant="outlined"
                    size="large"
                    component={Paper}
                    disableElevation
                    fullWidth
                    sx={{
                        mb: 3,
                        flexWrap: {
                            xs: 'wrap',
                            sm: 'nowrap',
                        },
                    }}
                >
                    <Button
                        component={Link}
                        href={routePath}
                        variant={pageKey === 'information' ? 'contained' : undefined}
                    >
                        <FormattedMessage defaultMessage="Information" id="E80WrK" />
                    </Button>
                    {!!query.controllers?.length && (
                        <Button
                            component={Link}
                            href={`${routePath}/live-updates/`}
                            variant={pageKey === 'live-updates' ? 'contained' : undefined}
                        >
                            <FormattedMessage defaultMessage="Live Updates" id="srXxxK" />
                        </Button>
                    )}
                    <Button
                        component={Link}
                        href={`${routePath}/participants/`}
                        variant={pageKey === 'participants' ? 'contained' : undefined}
                    >
                        <FormattedMessage defaultMessage="Participants" id="zx0myy" />
                    </Button>

                    {!!query.tournament?.categories.length && (
                        <Button
                            component="a"
                            onClick={e => setDrawsAnchorEl(e.currentTarget)}
                            variant={pageKey === 'draws' ? 'contained' : undefined}
                        >
                            <FormattedMessage defaultMessage="Draws" id="0aIuzB" />
                        </Button>
                    )}
                    <Menu
                        anchorEl={drawsAnchorEl}
                        open={Boolean(drawsAnchorEl)}
                        onClose={() => setDrawsAnchorEl(null)}
                    >
                        {query.tournament?.categories.map(category => (
                            <MenuItem
                                key={category.entityId}
                                component={Link}
                                selected={category.entityId === categoryEntityId}
                                href={`${routePath}/draw/${category.entityId}`}
                            >
                                {category.name}
                            </MenuItem>
                        ))}
                    </Menu>

                    <Button
                        component="a"
                        onClick={e => setResultsAnchorEl(e.currentTarget)}
                        variant={pageKey === 'results' ? 'contained' : undefined}
                    >
                        <FormattedMessage defaultMessage="Results" id="yaMHMB" />
                    </Button>
                    <Menu
                        anchorEl={resultsAnchorEl}
                        open={Boolean(resultsAnchorEl)}
                        onClose={() => setResultsAnchorEl(null)}
                    >
                        <MenuItem component={Link} href={`${routePath}/results-individual/`}>
                            <FormattedMessage defaultMessage="Individual Results" id="gkoU0i" />
                        </MenuItem>
                        <MenuItem component={Link} href={`${routePath}/results-team/`}>
                            <FormattedMessage defaultMessage="Team Results" id="5zaVo+" />
                        </MenuItem>
                    </Menu>

                    {!!query.tournament?.registration?.hasTitles && (
                        <Button
                            component={Link}
                            href={`${routePath}/officials/`}
                            variant={pageKey === 'officials' ? 'contained' : undefined}
                        >
                            <FormattedMessage defaultMessage="Officials" id="sOO/pE" />
                        </Button>
                    )}

                    {!!query.tournament?.videos.length && (
                        <Button
                            component={Link}
                            href={`${routePath}/videos/`}
                            variant={pageKey === 'videos' ? 'contained' : undefined}
                        >
                            <FormattedMessage defaultMessage="Videos" id="4XfMux" />
                        </Button>
                    )}
                </ButtonGroup>

                <Box>{typeof children === 'function' ? children(query.tournament) : children}</Box>
            </Container>
        </>
    );
};

export default TournamentPublicLayout;
